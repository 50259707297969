<template>
  <div v-if="productList.length > 0">
    <BannerSection :banners="banners" />
    <PopularItem :providerId="providerId" :productLists="productList" />
    <div v-for="(item, index) in productList" :key="item.id">
      <CategoryItem
        :providerId="providerId"
        :index="index"
        :productListItem="item"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "IndexView",
  components: {
    BannerSection: () =>
      import("@/modules/FrontProvider/components/index/BannerSection.vue"),
    PopularItem: () =>
      import("@/modules/FrontProvider/components/index/PopularItem.vue"),
    CategoryItem: () =>
      import("@/modules/FrontProvider/components/index/CategoryItem.vue"),
  },
  data() {
    return {
      productList: [],
      banners: [],
      providerId: this.$route.params.providerId,
    };
  },
  mounted() {
    this.getProductApi();
    this.getProductBanner();
  },
  methods: {
    productItemincategories(categories, productList) {
      if (productList) {
        productList.forEach((product) => {
          product.menus.forEach((menuId) => {
            let category = categories.find(
              (category) => category.id === menuId
            );
            if (category) {
              if (!category["productItem"]) {
                category["productItem"] = [];
              }
              category["productItem"].push(product);
            }
          });
        });
        this.productList = categories;
      } else {
        console.error("productList or productList.menus is undefined");
      }
    },
    async getProductApi() {
      this.$store.dispatch("loading/active");
      try {
        const productList = await this.$api.collection.publicApi.getProductIndex(
          this.$route.params.providerId
        );
        const categories = await this.$api.collection.publicApi.getProductMenu(
          this.$route.params.providerId
        );
        this.productItemincategories(categories.data, productList.data);
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async getProductBanner() {
      this.$store.dispatch("loading/active");
      try {
        const {
          banners,
        } = await this.$api.collection.publicApi.getProductBanner(
          this.$route.params.providerId
        );
        this.banners = banners;
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
  },
};
</script>

<style lang="sass" scoped>
main
  display: grid
  grid-template-columns: 1fr
  grid-gap:  1em
</style>
